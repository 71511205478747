<template>
  <div class="sidebar">
    <el-menu
      :default-active="onRoutes"
      class="el-menu-vertical-demo"
      :collapse="collapse"
      background-color="#26292C"
      active-text-color="#5188F4"
      router
    >
      <div class="cell" v-if="is_company != 1">
        <img class="logo_vce" src="@/assets/img/logo_vce.png" alt="" />
        <!-- <img class="head_img"
             src="@/assets/img/menu/head_img.png"
             alt="" /> -->
      </div>
      <div class="cell" v-else>
        <img
          class="avatar"
          :src="teacherInfo.avatar"
          :title="teacherInfo.nickname"
          alt=""
        />
        <div class="nickname hang1" :title="teacherInfo.nickname">
          {{ teacherInfo.nickname }}
        </div>
      </div>
      <!-- <el-menu-item
        :index="item.index"
        v-for="(item, index) in items"
        :key="index"
      >
        <img
          class="menu_icon"
          :src="onRoutes == item.index ? item.icon_active : item.icon"
          alt=""
        />
        <template #title>{{ item.name }}</template>
      </el-menu-item> -->
      <div class="menu_body overflow_y">
        <div v-for="(item, index) in items" :key="index">
          <template v-if="!item.children">
            <el-menu-item
              index=""
              v-if="item.is_show"
              :class="{ a_active: onRoutes == item.index }"
            >
              <a
                class="navigation_a"
                :href="base_url + item.index"
                target="_blank"
              >
                <img
                  class="menu_icon"
                  :src="onRoutes == item.index ? item.icon_active : item.icon"
                  alt=""
                />
                {{ item.name }}
              </a>
              <!-- <template #title>{{ item.name }}</template> -->
            </el-menu-item>
          </template>
          <template v-else>
            <el-sub-menu
              index=""
              v-if="item.is_show"
              :class="{ a_active: onRoutes == item.index }"
            >
              <template #title>
                <a class="navigation_a" target="_blank">
                  <img
                    class="menu_icon"
                    :src="onRoutes == item.index ? item.icon_active : item.icon"
                    alt=""
                  />
                  {{ item.name }}
                </a>
              </template>
              <el-menu-item-group>
                <div v-for="(items, indexs) in item.children" :key="indexs">
                  <el-menu-item index="">
                    <a
                      class="navigation_a"
                      :href="base_url + items.index"
                      target="_blank"
                    >
                      <!-- <img
                      class="menu_icon"
                      :src="
                        onRoutes == items.index ? items.icon_active : items.icon
                      "
                      alt=""
                    /> -->
                      {{ items.name }}
                    </a></el-menu-item
                  >
                </div>
              </el-menu-item-group>
            </el-sub-menu>
          </template>
        </div>
      </div>
      <div class="menu_bottom">
        <div @click="signOut" class="sign_out_btn">
          <!-- <img
            class="icon_exit"
            src="@/assets/img/icon_exit.png"
            title="退出登录"
            alt=""
          /> -->
          退出登录
        </div>
      </div>
    </el-menu>
  </div>
</template>

<script>
import * as Service from "@/api/chat";
export default {
  data() {
    return {
      collapse: false,
      items: [
        {
          index: "companyCircle",
          icon: require("@/assets/img/menu/icon_jishumoxing.png"),
          icon_active: require("@/assets/img/menu/icon_jishumoxing_active.png"),
          name: "圈子",
          is_show: localStorage.getItem("is_company") == 1 ? true : false,
        },
        {
          index: "bandOpportunity",
          icon: require("@/assets/img/menu/icon_jishumoxing.png"),
          icon_active: require("@/assets/img/menu/icon_jishumoxing_active.png"),
          name: "波段机会",
          is_show:
            localStorage.getItem("is_company") == 1
              ? localStorage.getItem("company_id") == 1
                ? true
                : false
              : false,
        },
        {
          index: "chat",
          icon: require("@/assets/img/menu/icon_chat.png"),
          icon_active: require("@/assets/img/menu/icon_chat_active.png"),
          name: "聊天",
          is_show: localStorage.getItem("is_company") == 1 ? false : true,
        },
        {
          index: "circleManage",
          icon: require("@/assets/img/menu/icon_wodekehu.png"),
          icon_active: require("@/assets/img/menu/icon_wodekehu_active.png"),
          name: "圈子管理",
          is_show: true,
        },
        {
          index: "statistics",
          icon: require("@/assets/img/menu/icon_data.png"),
          icon_active: require("@/assets/img/menu/icon_data_active.png"),
          name: "统计数据",
          is_show: localStorage.getItem("is_company") == 1 ? false : true,
        },
        {
          index: "businessDynamic",
          icon: require("@/assets/img/menu/icon_jiaoyidongtai.png"),
          icon_active: require("@/assets/img/menu/icon_jiaoyidongtai_active.png"),
          name: "交易动态",
          is_show: localStorage.getItem("is_company") == 1 ? false : true,
        },
        {
          index: "articleTemplate",
          icon: require("@/assets/img/menu/icon_wenzhangmuban.png"),
          icon_active: require("@/assets/img/menu/icon_wenzhangmuban_active.png"),
          name: "文章模板",
          is_show: localStorage.getItem("is_company") == 1 ? false : true,
        },
        {
          index: "marketEditor",
          icon: require("@/assets/img/menu/icon_hangqing.png"),
          icon_active: require("@/assets/img/menu/icon_hangqing_active.png"),
          name: "行情编辑",
          is_show: localStorage.getItem("is_company") == 1 ? false : true,
        },
        // {
        //   index: "enterpriseWechat",
        //   icon: require("@/assets/img/menu/icon_qiyeweixin.png"),
        //   icon_active: require("@/assets/img/menu/icon_qiyeweixin_active.png"),
        //   name: "企业微信",
        // },
        {
          index: "pushMessage",
          icon: require("@/assets/img/menu/icon_liaotianjilv.png"),
          icon_active: require("@/assets/img/menu/icon_liaotianjilv_active.png"),
          name: "推送消息",
          is_show: localStorage.getItem("is_company") == 1 ? false : true,
        },
        {
          index: "bbas",
          icon: require("@/assets/img/menu/icon_duokong.png"),
          icon_active: require("@/assets/img/menu/icon_duokong_active.png"),
          name: "多空情报",
          is_show: localStorage.getItem("is_company") == 1 ? false : true,
        },
        // {
        //   index: "riskPush",
        //   icon: require("@/assets/img/menu/icon_liaotianjilv.png"),
        //   icon_active: require("@/assets/img/menu/icon_liaotianjilv_active.png"),
        //   name: "风险推送",
        // },
        // {
        //   index: "technicalModel",
        //   icon: require("@/assets/img/menu/icon_jishumoxing.png"),
        //   icon_active: require("@/assets/img/menu/icon_jishumoxing_active.png"),
        //   name: "技术案例模型",
        //   is_show: localStorage.getItem("is_company") == 1 ? true : false,
        // },
        {
          index: "headlineReport",
          icon: require("@/assets/img/menu/icon_toutiaobaogao.png"),
          icon_active: require("@/assets/img/menu/icon_toutiaobaogao_active.png"),
          name: "头条报告",
          is_show: localStorage.getItem("is_company") == 1 ? false : true,
        },
        // {
        //   index: "viewingData",
        //   icon: require("@/assets/img/menu/icon_kanshuju.png"),
        //   icon_active: require("@/assets/img/menu/icon_kanshuju_active.png"),
        //   name: "看数据",
        //   is_show: localStorage.getItem("is_company") == 1 ? false : true,
        // },
        {
          index: "strategyList",
          icon: require("@/assets/img/menu/icon_toutiaobaogao.png"),
          icon_active: require("@/assets/img/menu/icon_toutiaobaogao_active.png"),
          name: "策略列表",
          is_show: [1].includes(localStorage.getItem("company_id") * 1)
            ? true
            : false,
        },
        {
          index: "varietyTracking",
          icon: require("@/assets/img/menu/icon_toutiaobaogao.png"),
          icon_active: require("@/assets/img/menu/icon_toutiaobaogao_active.png"),
          name: "品种追踪列表",
          is_show: [4, 5].includes(localStorage.getItem("company_id") * 1)
            ? true
            : false,
        },
        {
          index: "qiyouCommunity",
          icon: require("@/assets/img/menu/icon_qiyoushequ.png"),
          icon_active: require("@/assets/img/menu/icon_qiyoushequ_active.png"),
          name: "期友社区",
          is_show: localStorage.getItem("is_company") == 1 ? false : true,
        },
        // {
        //   index: "blackResearchReport",
        //   icon: require("@/assets/img/menu/icon_yanbao.png"),
        //   icon_active: require("@/assets/img/menu/icon_yanbao_active.png"),
        //   name: "黑色研报",
        //   is_show: localStorage.getItem("is_company") == 1 ? false : true,
        // },
        // {
        //   index: "qihuoshangpin",
        //   icon: require("@/assets/img/menu/icon_qihuoshangpin.png"),
        //   icon_active: require("@/assets/img/menu/icon_qihuoshangpin_active.png"),
        //   name: "期货商品",
        //   is_show: localStorage.getItem("is_company") == 1 ? false : true,
        // },
        {
          index: "", //
          icon: require("@/assets/img/menu/icon_kanshuju.png"),
          icon_active: require("@/assets/img/menu/icon_kanshuju_active.png"),
          name: "海报",
          is_show: localStorage.getItem("is_company") == 1 ? false : true,
          children: [
            {
              index: "viewingData",
              name: "看数据",
            },
            {
              index: "qihuoshangpin",
              name: "合约全景图",
            },
            {
              index: "blackResearchReport",
              name: "黑色系研报",
            },
            {
              index: "nongchanpin",
              name: "农产品研报",
            },
            {
              index: "yousejinshu",
              name: "有色金属研报",
            },
          ],
        },
      ],
      base_url: window.location.origin + "/#/",
      teacherInfo: {},
    };
  },
  computed: {
    onRoutes() {
      return this.$route.path.replace("/", "");
    },
  },
  created() {
    this.is_company = localStorage.getItem("is_company");
    this.getUserInfo();
  },
  methods: {
    signOut() {
      this.$confirm("即将退出, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        localStorage.setItem("vce-token-push", "");
        localStorage.setItem("is_company", "");
        this.$router.push("/login");
      });
    },
    async getUserInfo() {
      const res = await Service.getTeacherInfo();
      this.teacherInfo = res.data.userinfo;
    },
  },
};
</script>

<style scoped>
.sidebar {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 200px;
  overflow-y: scroll;
}

.cell {
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo_vce {
  height: 30px;
  display: block;
}
.head_img {
  width: 46px;
  height: 46px;
}
/deep/.el-sub-menu .el-icon {
  color: #fff;
}
.menu_body {
  height: calc(100vh - 110px);
  overflow-x: hidden;
}
.overflow_y {
  overflow-y: auto;
}

.overflow_y::-webkit-scrollbar {
  display: block;
  width: 4px;
}

.overflow_y::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px #aaa;
  background: rgba(0, 0, 0, 0.2);
}

.overflow_y::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px #aaa;
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}
.navigation_a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  color: #fff;
  text-decoration-line: none;
}
.menu_icon {
  width: 28px;
  height: 28px;
  position: relative;
  margin-right: 6px;
}
.sidebar::-webkit-scrollbar {
  width: 0;
}
.sidebar-el-menu:not(.el-menu--collapse) {
  width: 250px;
}
.sidebar > ul {
  height: 100%;
}
.el-menu--collapse {
  width: 100%;
}
.el-menu-item * {
  vertical-align: middle !important;
}
.el-menu-item.is-active {
  background-color: #5188f4;
}
.a_active {
  background-color: #5188f4;
}
.avatar {
  width: 36px;
  height: 36px;
  display: block;
  margin-right: 10px;
}
.nickname {
  font-size: 16px;
  color: #fff;
  text-align: center;
  box-sizing: border-box;
  padding: 0 5px;
}
.menu_bottom {
  width: 100%;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sign_out_btn {
  width: 50%;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
  background-color: #d81e06;
  border-radius: 8px;
}
.icon_exit {
  width: 30px;
  height: 30px;
  display: block;
  margin-right: 10px;
}
</style>
